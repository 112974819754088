<template>
  <div class="not-found">
    <h2>404</h2>
    <p>We can’t seem to find a page you’re looking for.</p>
  </div>
</template>

<style lang="scss">
.not-found {
  text-align: center;
}
</style>
